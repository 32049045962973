import React from "react";
import SeoTitle from "../Title/SeoTitle";
import Fade from "react-reveal/Fade";

const Service = () => {
  return (
    <React.Fragment>
      <section className="seo_service_area sec_pad">
        <div className="container">
          <SeoTitle
            Title="Services We Offer"
            TitleP="We bestow our clients with a wide spectrum of Website Development Services in Australia. Our peerless service spectrum encompasses."
          />
          <div className="row seo_service_info">
            <Fade bottom duration={500}>
              <div className="col-lg-4 col-md-6">
                <div className="seo_service_item">
                  <img src={require("../../img/seo/icon1.png")} alt="" />
                  <a href=".#">
                    <h4>Development</h4>
                  </a>
                  <p>
                    Partner with our experienced team and develop robust
                    software application, whether you’re a start-up or an
                    established business looking to outshine with innovative
                    ideas!
                  </p>
                  {/* 
                  <a href=".#">
                    <i className="arrow_right"></i>
                  </a>*/}
                </div>
              </div>
            </Fade>
            <Fade bottom duration={700}>
              <div className="col-lg-4 col-md-6">
                <div className="seo_service_item">
                  <img src={require("../../img/seo/icon2.png")} alt="" />
                  <a href=".#">
                    <h4>Web applications</h4>
                  </a>
                  <p>
                    Looking to build a new website or transform an existing one
                    that isn’t performing? We create engaging web applications
                    that offer the very best in user experience and drive
                    enquiries.
                  </p>
                  {/* 
                  <a href=".#">
                    <i className="arrow_right"></i>
                  </a>*/}
                </div>
              </div>
            </Fade>
            <Fade bottom duration={1000}>
              <div className="col-lg-4 col-md-6">
                <div className="seo_service_item">
                  <img src={require("../../img/seo/icon5.png")} alt="" />
                  <a href=".#">
                    <h4>Ecommerce</h4>
                  </a>
                  <p>
                    Feature-rich and conversion-optimised, our eCommerce
                    websites along with our industry leading experience give you
                    the control needed to grow your business.
                  </p>
                  {/* 
                  <a href=".#">
                    <i className="arrow_right"></i>
                  </a>*/}
                </div>
              </div>
            </Fade>
            {/* <div className="col-lg-12 text-center mt_40">
              <a href=".#" className="seo_btn seo_btn_one btn_hover">
                All Features
              </a>
            </div>*/}
          </div>
        </div>
      </section>
      <section className="seo_fact_area sec_pad">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-lg-6">
              <div className="seo_features_img">
                <div className="round_circle"></div>
                <div className="round_circle two"></div>
                <img src={require("../../img/seo/features_img.png")} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <Fade bottom cascade>
                <div className="seo_features_content">
                  <h2>Why DEVMARKER?</h2>
                  <p>
                    Our company is adept in crafting rewarding web solutions to
                    the global clientele. At MagicByte Solutions, our
                    professionals will assist you with Web development services
                    and more.
                    {/* Our web development team knows how to create a great balance between your requirement and your budget. */}
                  </p>
                  <div className="media seo_features_item">
                    <div className="icon">
                      <img src={require("../../img/seo/icon4.png")} alt="" />
                    </div>
                    <div className="media-body">
                      <h3>Skilled developers</h3>
                      <p>
                        Our developers have all the expertise required to build
                        and ensure the release of your project offering a great
                        user experience.
                      </p>
                    </div>
                  </div>
                  <div className="media seo_features_item">
                    <div className="icon two">
                      <img src={require("../../img/seo/icon3.png")} alt="" />
                    </div>
                    <div className="media-body">
                      <h3>Quality assurance</h3>
                      <p>
                        We follow a specific guideline while working on any
                        project which goes through quality testing to ensure the
                        high standard in our deliverables.
                      </p>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="seo_features_one sec_pad">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="seo_features_img seo_features_img_two">
                <div className="round_circle"></div>
                <div className="round_circle two"></div>
                <img
                  src={require("../../img/seo/features_img_two.png")}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center">
              <Fade bottom cascade>
                <div className="seo_features_content">
                  <h2 className="wow fadeInUp">
                    Get tips & tricks on how to skyrocket your sales.
                  </h2>
                  <h6 className="wow fadeInUp">
                    What a plonker bamboozled so I said say what a load of
                    rubbish owt to do with me haggle.
                  </h6>
                  <p className="wow fadeInUp">
                    Cheeky bugger gosh codswallop chap bamboozled blatant
                    cracking goal brown bread, pear shaped cor blimey guvnor
                    easy peasy lemon squeezy hotpot spiffing good time, chancer
                    a spend a penny spiffing I don't want no agro tinkety tonk
                    old fruit.
                  </p>
                  <a
                    href=".#"
                    className="seo_btn seo_btn_one btn_hover wow fadeInUp"
                  >
                    Learn More
                  </a>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
    */}
    </React.Fragment>
  );
};

export default Service;
